.gpt3__about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: var(--color-footer);


    padding: 2rem;

    /* ff 3.6+ */
    background:-moz-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* ie 10+ */
    background:-ms-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

}

.gpt3__about-heading {
    width: 100%;
    text-align: start;
    margin-bottom: 3rem;
}

.gpt3__about-heading h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 48px;
    line-height: 75px;
}

.gpt3__about-text {
    color: white;
    font-family: var(--font-family);
    font-size: 14px;
    line-height: 44px;
}

@media screen and (max-width: 850px) {
    .gpt3__about-heading h1 {
        font-size: 44px;
        line-height: 50px;
    }
}

@media screen and (max-width: 550px) {
    .gpt3__about-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }


}

@media screen and (max-width: 400px) {
    .gpt3__about-heading h1 {
        font-size: 27px;
        line-height: 38px;
    }
}